import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { map } from 'rxjs';
import { DropdownDirective } from '../../dropdown/directives';
import {
	dateStringToISO,
	ISOStringToDateString,
	isStringValidDate,
	stringToDate,
} from '@agilox/common';
import { CalendarWeekOutput } from '@agilox/ui-common';

@Component({
	selector: 'ui-datepicker-input',
	templateUrl: './datepicker-input.component.html',
	styleUrl: './datepicker-input.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerInputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DatepickerInputComponent),
			multi: true,
		},
	],
})
export class DatepickerInputComponent implements ControlValueAccessor, OnInit {
	@Input() placeholder: string = '';
	@Input() hasError: boolean = false;
	@Input() inputId: string = '';
	@Input() syncedDate: Date | undefined;
	@Input() showWhenValid: boolean = false;
	@Input() value: Date | undefined = undefined;
	@Input() fullWidth: boolean = false;
	@Input() minDate: Date | undefined;
	@Input() maxDate: Date | undefined;
	@Output() iconClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() calendarWeekClicked: EventEmitter<CalendarWeekOutput> =
		new EventEmitter<CalendarWeekOutput>();

	@ViewChild('input') input: HTMLInputElement | undefined;
	@ViewChild(DropdownDirective) dropdown: DropdownDirective | undefined;

	formControl: FormControl<string | null> = new FormControl<string>('');

	@Input() currentlyDisplayedMonth: Date = new Date();

	focused: boolean = false;

	dropdownOpened: boolean = false;

	ngOnInit() {
		this.formControl.valueChanges.pipe(map(this.doMask)).subscribe((value: string) => {
			this.formControl.setValue(value, { emitEvent: false });
			if (this.isValid(value)) {
				const isoDate: string = dateStringToISO(value);
				this.currentlyDisplayedMonth = new Date(isoDate);
				this.onChange(isoDate);
				this.onTouched();
			} else {
				this.onChange(null);
			}
		});
	}

	private isValid(value: string) {
		return isStringValidDate(value) ? this.isBetweenMinMax(value) : false;
	}

	private setErrors(value: string | null) {
		if (!value) {
			return;
		}
		this.hasError = !this.isValid(value);
	}

	private isBetweenMinMax(date: string): boolean {
		const largerThanMin: boolean = this.minDate ? stringToDate(date) >= this.minDate : true;
		const smallerThanMax: boolean = this.maxDate ? stringToDate(date) <= this.maxDate : true;
		return largerThanMin && smallerThanMax;
	}

	/**
	 * Mask the input value
	 * e.g. input: 1234567890 -> output: 12.34.5678
	 *
	 *
	 * @param value
	 * @private
	 */
	private doMask(value: string | null): string {
		if (!value) {
			return '';
		}
		// remove all non digit characters
		const sanitized = value.replace(/[^\d]/g, '');

		const dotPositions: number[] = [2, 4];
		let result: string = '';
		for (let i = 0; i < sanitized.length; i++) {
			if (dotPositions.includes(i) && !(i > 0 && sanitized[i - 1] === '.')) {
				result += '.';
			}
			result += sanitized[i];
		}
		return result.slice(0, 10);
	}

	onChange: any = () => {};
	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(obj: any) {
		const val = obj ? ISOStringToDateString(obj) : '';
		this.formControl.setValue(val, { emitEvent: false });
	}

	setDisabledState(isDisabled: boolean) {
		if (isDisabled) {
			this.formControl.disable({ emitEvent: false });
		} else {
			this.formControl.enable({ emitEvent: false });
		}
	}

	calendarSelection(date: string) {
		this.formControl.setValue(ISOStringToDateString(date));
		this.dropdown?.closeDropdown();
	}

	navDateChange(date: Date) {
		this.currentlyDisplayedMonth = date;
	}

	openCalendar() {
		// Wait for the next tick to open the dropdown
		setTimeout(() => {
			this.dropdown?.openDropdown();
		});
	}

	closeCalendar() {
		this.dropdown?.closeDropdown();
	}

	onDropdownStateChange(opened: boolean) {
		this.dropdownOpened = opened;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}
		this.setErrors(this.formControl.value);

		return null;
	}
}
