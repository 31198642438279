import { createFeature, createReducer, on } from '@ngrx/store';
import { setMode, setMultipleVehicles, setSingleVehicle } from './vehicle-filter.actions';
import { Mode } from '../../general/shared/services/appsettings/mode.enum';

export interface VehiclesFilter {
	selectedVehicles: string[];
	mode: Mode;
	machineToShow: string;
}

const initialState: VehiclesFilter = {
	selectedVehicles: [],
	mode: Mode.sum,
	machineToShow: undefined,
};

const reducer = createReducer(
	initialState,
	on(setMultipleVehicles, (state, action) => ({ ...state, selectedVehicles: action.value })),
	on(setSingleVehicle, (state, action) => ({ ...state, machineToShow: action.value })),
	on(setMode, (state, action) => ({ ...state, mode: action.value }))
);

export const vehicleFilterFeature = createFeature({
	name: 'globalVehicleFilter',
	reducer,
});
