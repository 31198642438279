import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	BehaviorSubject,
	Observable,
	Observer,
	of,
	PartialObserver,
	Subject,
	Subscription,
} from 'rxjs';
import { HeaderMachine } from '../machine/header-machine';
import { AppSettings } from './app-settings';
import { DateSelector } from './date-selector.interface';
import { Granularity } from './granularity.enum';
import { LocalStorageService } from './local-storage.service';
import { Mode } from './mode.enum';
import { environment } from '@analytics/env/environment';
import { User, Role } from '@agilox/common';
import { map } from 'rxjs/operators';
import { DateFilter } from '../../../../store/date-filter/date-filter.state';
import { ApplicationSettings } from '../../../interfaces/application-settings.interface';

/**
 * provides all informations to the appsettings
 */
@Injectable({
	providedIn: 'root',
})
export class AppsettingsService {
	/** user */
	user: User;
	/** webappsettings */
	webAppSettings: AppSettings;
	/** is initialized */
	isInitialized = false;
	/** is help popup visible */
	isHelpPopupVisible = false;

	/** date selector */
	get dateSelector(): DateSelector {
		return this.webAppSettings?.dateSelector;
	}

	/** gets next if done initializing */
	private onInitializedSubject = new Subject<void>();
	/** gets invoked if DateSelectorChanged */
	public onDateSelectorChanged = new Subject<DateSelector>();
	/** gets invoked if the mode gets changed */
	private onModeChanged = new Subject<Mode>();
	/** gets invoked if a machines gets selected */
	public onMachineSelected = new Subject<Array<string> | string>();
	/** gets invoked when a new machine gets selected with the each togglers */
	private onEachMachineSelectedSubject = new Subject<string>();
	/** gets invoked on user changed */
	private onUserChangedSubject = new Subject<User>();
	/** save settings subscription */
	private saveSettingsSubscription: Subscription;

	private roleChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');
	onRoleChanged$: Observable<string> = this.roleChanged.asObservable();

	/**
	 * constructs the appsettings service
	 * @param translate needed for translation
	 * @param http need for server requests
	 * @param localStorageService
	 */
	constructor(
		private translate: TranslateService,
		private http: HttpClient,
		private localStorageService: LocalStorageService
	) {}

	/**
	 * register an observer for notifing when the app is initialized
	 * @param observer gets notified when the app is initialized
	 */
	registerOnInitialized(observer: Observer<void>): Subscription {
		return this.onInitializedSubject.subscribe(observer);
	}

	/**
	 * Initializes from the login response
	 * @param response repsonse from login request
	 */
	setFromResponse(data: any): void {
		if (data.userProfile) {
			this.user = data.userProfile;
			this.roleChanged.next(this.user.role);
			this.webAppSettings = AppSettings.generateFromJson(
				JSON.parse(data.userProfile.webapp_settings)
			);
			this.localStorageService.saveUserSettingsInLocalStorage(this.user, this.webAppSettings);
			this.setLanguage();
			// all appsettings are loaded, the app is initialized
			this.isInitialized = true;
			this.onInitializedSubject.next();
		}
	}

	/**
	 * set user profile and appsettings from storage
	 * @param userprofile user profile
	 * @param appsettings appsettings
	 */
	setFromStorage(userprofile: User, appsettings: any) {
		this.user = userprofile;
		const validAppsettings = AppSettings.isValidUserObject(appsettings);
		this.webAppSettings = AppSettings.generateFromJson(appsettings);
		this.setLanguage();
		if (!validAppsettings) {
			this.saveAppSettingsOnServer();
		}
		this.isInitialized = true;
		this.onInitializedSubject.next();
	}

	/**
	 * saves the appsettings on the server
	 */
	saveAppSettingsOnServer(): void {
		const reqObject = { webapp_settings: JSON.stringify(this.webAppSettings.toJson()) };
		this.localStorageService.saveUserSettingsInLocalStorage(this.user, this.webAppSettings);

		// saving the settings
		this.saveSettingsSubscription?.unsubscribe();
		this.saveSettingsSubscription = this.http
			.post(environment.server + '/v2/User/saveSettings', reqObject)
			.subscribe();
	}

	saveAppSettings(): any {
		const reqObject = { webapp_settings: JSON.stringify(this.webAppSettings.toJson()) };
		this.localStorageService.saveUserSettingsInLocalStorage(this.user, this.webAppSettings);

		// saving the settings
		this.saveSettingsSubscription?.unsubscribe();
		return this.http.post(environment.server + '/v2/User/saveSettings', reqObject);
	}

	/**
	 * checks if the machines that are in the webappsettings can also be selected in the machine selector machines
	 * @param machines machines
	 */
	checkMachines(machines: HeaderMachine[]) {
		const selected = this.webAppSettings.selectedMachines.filter((selectedMachine) =>
			machines.find((machine) => machine.serial === selectedMachine)
		);
		const machineToShow = machines.find(
			(machine) => machine.serial === this.webAppSettings.machineToShow
		)?.serial;
		if (
			selected.length !== this.webAppSettings.selectedMachines.length ||
			(!machineToShow && this.webAppSettings.machineToShow)
		) {
			this.webAppSettings.selectedMachines = selected;
			this.webAppSettings.machineToShow = machineToShow
				? machineToShow
				: selected?.length
					? selected[0]
					: '';
			this.saveAppSettingsOnServer();
		}
	}

	/**
	 * loads the settings from the localstorage and return true if successfull
	 */
	loadSettingsFromLocalStorage(): boolean {
		const savedProfile = this.localStorageService.loadFromLocalStorage('profile');
		const savedAppsettings = this.localStorageService.loadFromLocalStorage('appsettings');
		if (savedProfile && savedAppsettings) {
			this.setFromStorage(savedProfile, savedAppsettings);
			return true;
		}
		return false;
	}

	/**
	 * Generates a standard required object
	 * @returns generates a standard reqObject for requests
	 */
	generateReqObject(allMachine: boolean = false): any {
		const dateSelector = this.dateSelector;
		let serials = this.webAppSettings.selectedMachines;
		// even if each mode is active, if the show-all-popup is open it should load every machine
		if (this.webAppSettings.dateSelector.mode === Mode.each && !allMachine) {
			if (this.webAppSettings.selectedMachines.length > 0) {
				if (!this.webAppSettings.machineToShow) {
					this.webAppSettings.machineToShow = this.webAppSettings.selectedMachines[0];
				}
				serials = [this.webAppSettings.machineToShow];
			} else {
				serials = [];
			}
		}

		const res: any = {
			from: dateSelector.startDate / 1000,
			to: dateSelector.endDate / 1000,
			res: dateSelector.granularity,
			serial: serials,
			sum: this.webAppSettings.dateSelector.mode === Mode.sum,
		};

		if (dateSelector.shiftOn || this.webAppSettings.dateSelector.granularity === Granularity.dd) {
			const startDate = new Date(dateSelector.startDate).setUTCHours(0, 0, 0);
			const endDate = new Date(dateSelector.endDate).setUTCHours(23, 59, 59);
			res.from = startDate / 1000;
			res.to = endDate / 1000;
		}

		if (dateSelector.shiftOn) {
			res.shift = this.getShiftSettings();
		}
		return res;
	}

	/**
	 * generates the shift object
	 */
	private getShiftSettings(): any {
		return {
			on: this.dateSelector.shiftOn,
			start: this.dateSelector.shiftStart / 1000,
			end: this.dateSelector.shiftEnd / 1000,
			days: this.dateSelector.shiftDays,
		};
	}

	/**
	 * returns the date with hours and minutes
	 * @param milliseconds date
	 * @param hours hours
	 * @param minutes minutes
	 */
	getDate(milliseconds: number, hours: number, minutes: number) {
		const date = new Date(milliseconds);
		date.setUTCHours(hours);
		date.setUTCMinutes(minutes);
		return +date;
	}

	/**
	 * Register an observer which gets notified
	 * @param observer observer you want to register
	 */
	registerOnDateSelector(observer: PartialObserver<DateSelector>): Subscription {
		return this.onDateSelectorChanged.subscribe(observer);
	}

	/**
	 * Register an observer which gets notified
	 * @param observer observer you want to register
	 */
	registerOnUserChanged(observer: PartialObserver<User>): Subscription {
		return this.onUserChangedSubject.subscribe(observer);
	}

	/**
	 * Register an observer which gets notified when the mode changes
	 * @param observer observer
	 */
	registerOnModeChanged(observer: PartialObserver<Mode>): Subscription {
		return this.onModeChanged.subscribe(observer);
	}

	/**
	 * gets invoked if a machine was selected
	 * @param observer gets notified
	 */
	registerOnMachineSelected(observer: PartialObserver<Array<string>>): Subscription {
		return this.onMachineSelected.subscribe(observer);
	}

	/**
	 * gets invoked if a new machine to display under the each mode is selected
	 * @param observer gets notified
	 */
	registerOnEachMachineSelected(observer: PartialObserver<string>): Subscription {
		return this.onEachMachineSelectedSubject.subscribe(observer);
	}

	/**
	 * should get invoked when multiple machines get selected
	 * if in sum 20 or more machines would be selected, no other machine can be selected
	 * @param machines machines
	 */
	multipleMachinesClicked(machines: Array<string>): boolean {
		if (machines.length > 20) {
			return false;
		}

		this.webAppSettings.selectedMachines = machines;
		const selectedMachines = this.webAppSettings.selectedMachines;
		this.webAppSettings.machineToShow = selectedMachines.length
			? selectedMachines[selectedMachines.length - 1]
			: '';

		this.saveAppSettingsOnServer();
		this.onMachineSelected.next(selectedMachines);
		return true;
	}

	/**
	 * Saves the choosen date-selector data and invokes the subscribers
	 * (all timestamps in ms please)
	 * @param date the data of the dateselector
	 */
	saveDateSelector(date: DateSelector) {
		this.webAppSettings.dateSelector = date;
		this.onDateSelectorChanged.next(this.webAppSettings.dateSelector);
		this.saveAppSettingsOnServer();
	}

	/**
	 * saves the mode and invokes the observers of registerOnModeChanged
	 * @param mode selected mode
	 */
	saveMode(mode: Mode) {
		this.webAppSettings.dateSelector = { ...this.webAppSettings.dateSelector, mode };

		// each mode is selected and either no machineToShow was previously selected or the previously selected machineToShow
		if (this.webAppSettings.dateSelector.mode === Mode.each) {
			if (
				!this.webAppSettings.machineToShow ||
				!this.webAppSettings.selectedMachines.find(
					(machine) => machine === this.webAppSettings.machineToShow
				)
			) {
				this.webAppSettings.machineToShow = this.webAppSettings.selectedMachines[0];
			}
		}
		this.onModeChanged.next(mode);
		this.saveAppSettingsOnServer();
	}

	/**
	 * updates user
	 * @param user user
	 */
	updateUser(user?: User) {
		if (user) {
			this.user = user;
		}
		this.localStorageService.saveUserSettingsInLocalStorage(this.user, this.webAppSettings);
		this.onUserChangedSubject.next(user as User);
	}

	/**
	 * sets the language, either given via parameter or just applies the language of the userprofile
	 * @param language choosen language
	 */
	setLanguage(language?: string) {
		if (language) {
			this.user.lang = language;
			this.translate.use(language.toLowerCase());
		} else if (this.user) {
			this.translate.setDefaultLang(this.user.lang);
			this.translate.use(this.user.lang);
		} else {
			this.translate.setDefaultLang('en');
			this.translate.use('en');
		}
		this.updateUser(this.user);
	}

	/**
	 * sets machine to show
	 * @param machine machine serial
	 */
	machineToShowSelected(machine: string) {
		this.webAppSettings.machineToShow = machine;
		this.saveAppSettingsOnServer();
		this.onEachMachineSelectedSubject.next(machine);
	}

	/**
	 * registers an observer on:
	 *  * DateSelector
	 *  * MachineToShow selected
	 *  * Machine selected
	 *  * Mode changed
	 * @param observer observer
	 */
	registerOnWebappSettingChanged(
		observer: PartialObserver<string | Array<string> | DateSelector | Mode>
	): Array<Subscription> {
		const arr = new Array<Subscription>();
		arr.push(this.registerOnDateSelector(observer));
		arr.push(this.registerOnEachMachineSelected(observer));
		arr.push(this.registerOnMachineSelected(observer));
		arr.push(this.registerOnModeChanged(observer));
		return arr;
	}

	/**
	 * check if user is an internal user
	 * @returns true if the role is support or superuser
	 */
	isInternalUser(): boolean {
		return (
			this.user?.role === Role.support ||
			this.user?.role === Role.service ||
			this.user?.role === Role.superuser
		);
	}

	/**
	 * used for Google Analytics to check the user role
	 * @returns the user role
	 */
	getUserRole(): string {
		return this.user?.role;
	}

	getUserAppSettings(): Observable<ApplicationSettings> {
		return this.http
			.get<{
				userProfile: User & { webapp_settings: string };
			}>(environment.server + '/v2/User/getUserInfo')
			.pipe(
				map((v) => {
					return JSON.parse(v.userProfile.webapp_settings);
				})
			);
	}

	saveGlobalDateFilter(dateSelector: DateFilter): Observable<ApplicationSettings> {
		const mode = this.webAppSettings.dateSelector.mode;
		this.saveDateSelector({ ...dateSelector, mode } as DateSelector);
		return of({
			...this.webAppSettings,
			dateSelector: { ...this.webAppSettings.dateSelector, ...dateSelector },
		});
	}

	saveGlobalMultiSelectVehicles(selectedMachines: string[]) {
		const mode = selectedMachines.length > 1 ? this.webAppSettings.dateSelector.mode : Mode.each;
		this.multipleMachinesClicked(selectedMachines);
		return of({
			...this.webAppSettings,
			dateSelector: { ...this.webAppSettings.dateSelector, mode },
			selectedMachines,
		});
	}

	saveSelectedMode(mode: Mode) {
		this.saveMode(mode);
		return of(mode);
	}

	saveGlobalSingleSelectVehicles(machineToShow: string) {
		this.machineToShowSelected(machineToShow);
		return of({
			...this.webAppSettings,
			dateSelector: { ...this.webAppSettings.dateSelector, mode: Mode.each },
			machineToShow,
		});
	}
}
