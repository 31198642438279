import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { SelectorEvent } from './selector-event.interface';

@Injectable({
	providedIn: 'root',
})
export class SelectorService {
	private openSelectorSubject = new Subject<SelectorEvent>();
	public openSelector$ = this.openSelectorSubject.asObservable();

	registerOnSelectorSubject(observer: (evt: SelectorEvent) => void): Subscription {
		return this.openSelectorSubject.subscribe(observer);
	}

	openSelector(event: SelectorEvent) {
		this.openSelectorSubject.next(event);
	}
}
