import { createAction, props } from '@ngrx/store';

export const setMultipleVehicles = createAction(
	'[Global Vehicle Filter] Set Multiple Select',
	props<any>()
);
export const setSingleVehicle = createAction(
	'[Global Vehicle Filter] Set Single Select',
	props<any>()
);

export const saveMultipleVehicles = createAction(
	'[Global Vehicle Filter] Save Multiple Select',
	props<any>()
);
export const saveSingleVehicle = createAction(
	'[Global Vehicle Filter] Save Single Select',
	props<any>()
);
export const saveMode = createAction('[Global Vehicle Filter] Save Mode', props<any>());
export const setMode = createAction('[Global Vehicle Filter] Set Mode', props<any>());
