import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import {
	saveMode,
	saveMultipleVehicles,
	setMode,
	setMultipleVehicles,
} from './vehicle-filter.actions';
import { map, tap } from 'rxjs/operators';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';
import { Mode } from '../../general/shared/services/appsettings/mode.enum';

@Injectable()
export class VehicleFilterEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveSelectedMode$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveMode),
				mergeMap((action: { value: Mode }) =>
					this.appSettingsService
						.saveSelectedMode(action.value)
						.pipe(map((mode: Mode) => setMode({ value: mode })))
				)
			),
		{ dispatch: true }
	);

	saveMultipleSelectedVehicles$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveMultipleVehicles),
				mergeMap((action: { value: string[] }) =>
					this.appSettingsService
						.saveGlobalMultiSelectVehicles(action.value)
						.pipe(
							map((filter: ApplicationSettings) =>
								setMultipleVehicles({ value: filter.selectedMachines })
							)
						)
				)
			),
		{ dispatch: true }
	);
}
