import { Meta, Vehicle } from '@agilox/common';
import { SelectComponent, SelectModule } from '@agilox/ui';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, forwardRef, inject, Input, ViewChild } from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	tap,
} from 'rxjs';
import { VehicleSelectPipe } from './pipes/vehicle-select.pipe';
import { VehiclesSelectService } from './vehicles-select.service';

@Component({
	selector: 'ui-vehicles-select',
	templateUrl: './vehicles-select.component.html',
	standalone: true,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VehiclesSelectComponent),
			multi: true,
		},
		VehiclesSelectService,
	],
	imports: [SelectModule, TranslateModule, AsyncPipe, VehicleSelectPipe, ReactiveFormsModule],
})
export class VehiclesSelectComponent implements ControlValueAccessor {
	private service: VehiclesSelectService = inject(VehiclesSelectService);
	private destroyRef: DestroyRef = inject(DestroyRef);
	private search$ = new BehaviorSubject<string>('');
	private searchObservable$ = this.search$
		.asObservable()
		.pipe(debounceTime(300), distinctUntilChanged());
	private page: Meta = { number: 0, size: 50 };
	private currentPage: number = 1;

	private paginationSubject = new Subject<Meta>();
	private pagination$ = this.paginationSubject.asObservable().pipe(
		startWith({
			number: 0,
			size: 50,
		})
	);

	initialLoad: boolean = true;

	public vehicleResponse: Observable<Vehicle[]> = combineLatest([
		this.searchObservable$,
		this.pagination$,
	]).pipe(
		tap(() => this.formControl.disable({ emitEvent: false })),
		debounceTime(300),
		switchMap(([search, page]) => this.service.fetchVehicles(search, page, this.selectedSerials)),
		tap((data) => {
			this.page = data.meta;
			this.formControl.enable({ emitEvent: false });
			if (this.initialLoad) {
				this.initialLoad = false;
				this.setVehiclesToSelectedVehiclesFromSerialStrings(this.selectedSerials, data.data);
			}
		}),
		map((data) => data.data)
	);
	@ViewChild(SelectComponent, { static: true }) selectComponent: SelectComponent | undefined;

	@Input() multiple: boolean = false;

	@Input() selectedSerials: string[] = [];

	@Input() maxSelections: number | undefined;

	@Input() maxSelectionsText: string = 'select.maxSelectionsText';

	/**
	 * Need to cache the selectedVehicles on the initial write
	 * otherwise not all vehicles will be selected.
	 */
	selectedVehicles: Vehicle[] = [];

	public formControl: FormControl = new FormControl({ value: [], disabled: true });
	onChange: any = () => {};
	onTouched: any = () => {};

	writeValue(obj: any): void {
		this.selectedVehicles = obj;
		this.formControl.setValue(obj);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });
	}

	onSearch(query: string) {
		this.search$.next(query);
	}

	onScroll() {
		this.currentPage++;
		this.page.size = 50 * this.currentPage;
		if (this.page.size < (this.page?.total || 0)) {
			this.paginationSubject.next(this.page);
		}
	}

	/**
	 * Needed in cases where the selected vehicles are passed as serials
	 * @example
	 * We get 123481723,1230129384
	 * When we receive the vehicles from the backend, we need to set the vehicles that have the serials 123481723 and 1230129384
	 * to the form control
	 *
	 * @param serials
	 * @param vehicles
	 * @private
	 */
	private setVehiclesToSelectedVehiclesFromSerialStrings(
		serials: string[],
		vehicles: Vehicle[]
	): void {
		if (serials?.length && vehicles?.length) {
			const selectedVehicles = vehicles.filter((vehicle) => serials.includes(vehicle.serial));
			this.formControl.setValue(selectedVehicles, { emitEvent: false });
		}
	}

	public onOpened() {
		if (this.page.number !== 0 || this.page.size !== 50) {
			this.paginationSubject.next({ number: 0, size: 50 });
		}
		if (this.search$.value !== '') {
			this.search$.next('');
		}
	}

	onSave() {
		const selectedSerials = this.formControl.value;
		this.onChange(selectedSerials);
	}
}
